.big-subscribe .subscribe {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;

  .subscribe-icon {
    cursor: pointer;
    font-size: 4rem;
    margin: 1rem;
    outline: none;

    color: lighten($secondary, 30%);
    &:hover { color: $secondary; }
  }
}

.subscribe-modal {
  .qrcode {
    display: block;
    margin: 0 auto;
  }
}
