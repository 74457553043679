.source-wrapper {
  @extend .col-sm-6;
  @extend .col-md-4;
  @extend .col-lg-3;
}

.source {
  @extend .mb-4;
  border: none;
  background-color: inherit;

  .source-title {
    font-size: 1rem;
    font-weight: bold;

    max-height: 2.4rem;
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-line-clamp: 2;
  }

  .source-author {
    font-size: .9rem;
  }
}
