$sidebar-width: 300px;
$sidebar-bg: lighten($dark, 6%);

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: $sidebar-bg;

  color: #FFF;
  a {
    color: #FFF;
  }

  height: 100%;

  padding-top: $navbar-height-lg + 15px;
  @include media-breakpoint-down(sm) { padding-top: $navbar-height-sm + 15px; }

  padding-bottom: 15px;

  width: $sidebar-width;

  left: -$sidebar-width;
  transition: left 0.3s;
  &.active {
    left: 0;
    box-shadow: 2px 0px 15px #000;
  }

  .nav {
    display: block;
    width: 100%;
  }
  .top {
    align-self: start;
  }
  .bottom {
    align-self: end;
  }
}
