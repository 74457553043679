$navbar-height-sm: 50px;
$navbar-height-lg: 65px;

.navbar {
  @extend .mb-3;

  display: flex;
  justify-content: center;
  align-content: center;

  height: $navbar-height-lg;
  @include media-breakpoint-down(sm) { height: $navbar-height-sm; }

  box-shadow: 0px 2px 3px #000;

  .logo {
    height: 100%;
    justify-self: center;
    align-self: center;
  }
}

.body-app {
  padding-top: $navbar-height-lg + 15px;
  @include media-breakpoint-down(sm) { padding-top: $navbar-height-sm + 15px; }
}
