.source-thumbnail-wrapper {
  @extend .mb-2;

  position: relative;
  width: 100%;
  background-color: #000000;

  @include media-breakpoint-down(lg) { height: 118px; }
  @include media-breakpoint-down(sm) { height: 135px; }
  @include media-breakpoint-down(xs) { height: 170px; }
  height: 144px;

  display: flex;
  align-items: center;
  justify-content: center;

  .source-thumbnail {
    max-width: 100%;
    max-height: 100%;

    &.placeholder {
      width: 100%;
      height: 100%;
    }
  }

  .placeholder-icon {
    color: $brand-orange;
    position: absolute;
    margin: auto;
    font-size: 6rem;
  }

          .actions { display: none; }
  &:hover .actions { display: flex; }
}
